/*Top Bar (login, search etc.)
------------------------------------*/
#header {
	position:relative;
	overflow:hidden;
	background-color: $dunkelgrau;
	border-bottom: 1px solid $black;
	min-height:30px;

	.nav{
		float:right;

		ul.subnavi{
			padding:0;
			margin:0;
			list-style: none;

			li{
				float: left;
				margin: 1px 4px;
				font-weight:bold;
				font-size:0.8em;
				text-shadow: 1px 1px 0 $braun;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				padding:5px 10px;
				color:$bage;
			}

			a{
				color:$white;
				text-decoration: none;

				&:hover, &:focus {
					color:$bage;
				}
			}
		}
	}
}
