#footer{
	position: absolute;
	bottom:0;
	left:0;
	right:0;
	//margin-top: -150px; /* Negativer Wert der Footer-Höhe */
	height: 150px;
	background-color:$dunkelgrau;
	color: $hellbage;
	border-top: 1px solid $black;
	clear:both;
	font-size:0.75em;

	.inside{
		padding-top: 10px;
		padding-bottom: 10px;
	}

	//ul{
	//	list-style:none;
	//	margin:0;
	//	padding:0;
	//
	//	li{
	//		float:left;
	//		width: 33%;
	//	}
	//}

	a{
		color: $hellbage;
		text-decoration:none;

		&:hover,
		&:focus{
			color:$white;
		}

		&.pdf-icon{
			background:url('../../images/pdf-icon24.png') no-repeat left center transparent;
			line-height: 24px;
			padding-left: 30px;
		}
	}
}

@media screen and (max-width: 768px){
	.inside{
		padding-left:2%;
		padding-right:2%;
	}
	#footer{
		height: auto;
		min-height:150px;
	}
	#footer li{
		float:none;
		width: 100%;
		padding: 10px 0;
	}
	#footer li.col_middle,
	#footer li.col_right{
		text-align: left;
	}
}


