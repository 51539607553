#header{
  .navbar {
    margin: 0;
    padding:0;
    min-height: 45px;
  }

  .navbar-brand {
    z-index: 1;
    height: 100%;
    padding:0;
    position: relative;

    @include media-breakpoint-down(md) {
      line-height: normal;
    }
    @include media-breakpoint-down(sm) {
      max-width: 60%;
    }

    img{
      max-width: 100%;
      height: auto;
    }
  }

  /*Navbar Toggle*/
  .navbar-toggler {
    border-color: darken($primary,20%);
    color: $white;
  }

  .navbar-toggler,
  .navbar-toggler:hover,
  .navbar-toggler:focus {
    background: $primary;
    padding: 2px 10px 2px;
  }

  .navbar-toggler:hover {
    background: darken($primary,20%) !important;
  }

  .mod_navigation{
    overflow: visible;
  }


  /*Navbar Collapse*/
  .navbar-collapse {
    border: none;
    background-color: $white;
    position: relative;
    max-height: none;

    .navbar-nav {
      & > li > .nav-link{
        color: #687074;
        font-size: 15px;
        font-weight:400;
        text-transform: uppercase;
        bottom: -2px;
        border-bottom: solid 2px transparent;
        position: relative;
        padding: .8rem 2rem;
      }
      & > li > a:hover,
      & > .active > strong {
        color: $blue;
        position: relative;
        border-bottom: solid 2px $blue;
      }

      .dropdown-menu{
        font-size: 1em;
      }

      @include media-breakpoint-down(md) {
        //max-width: 60%;
      }
    }
  }

  /*Responsive Navbar*/
  @media (max-width: 991px) {
  //  /*Responsive code for max-width: 991px*/

  //  .navbar-collapse.collapse.in {
  //    display: block !important;
  //    overflow-y: auto !important;
  //  }
  //
  //  .navbar-nav {
  //    margin: 0 0 5px;
  //    float: none !important;
  //
  //    & > li {
  //      float: none;
  //
  //      & > a {
  //        padding-top: 10px;
  //        padding-bottom: 10px;
  //      }
  //    }
  //
  //    /*Dropdown Menu Slide Down Effect*/
  //    .open .dropdown-menu {
  //      border: 0;
  //      float: none;
  //      width: auto;
  //      margin-top: 0;
  //      position: static;
  //      box-shadow: none;
  //      background-color: transparent;
  //    }
  //  }
  //
  //
  //
  //
  //  .navbar-nav .open .dropdown-menu > li > a,
  //  .navbar-nav .open .dropdown-menu .dropdown-header {
  //    padding: 5px 15px 5px 25px;
  //  }
  //
  //  .navbar-nav .open .dropdown-menu > li > a {
  //    line-height: 20px;
  //  }
  //
  //   .navbar-nav .open .dropdown-menu > li > a:hover,
  //   .navbar-nav .open .dropdown-menu > li > a:focus {
  //    background-image: none;
  //  }
  //
  //   .navbar-collapse .navbar-nav .open .dropdown-menu > li > a {
  //    color: #777;
  //  }
  //
  //   .navbar-collapse .navbar-nav .open .dropdown-menu > li > a:hover,
  //   .navbar-collapse .navbar-nav .open .dropdown-menu > li > a:focus {
  //    color: #333;
  //    background-color: transparent;
  //  }
  //
  //   .navbar-collapse .navbar-nav .open .dropdown-menu > .active > a,
  //   .navbar-collapse .navbar-nav .open .dropdown-menu > .active > a:hover,
  //   .navbar-collapse .navbar-nav .open .dropdown-menu > .active > a:focus {
  //    color: #555;
  //    background-color: #e7e7e7;
  //  }
  //
  //   .navbar-collapse .navbar-nav .open .dropdown-menu > .disabled > a,
  //   .navbar-collapse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  //   .navbar-collapse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
  //    color: #ccc;
  //    background-color: transparent;
  //  }
  //
  //   .navbar-collapse .dropdown-menu.no-bottom-space {
  //    padding-bottom: 0;
  //  }
  //
  //  /*Design for max-width: 991px*/
  //   .navbar-collapse,
  //   .navbar-collapse .container {
  //    padding-left: 0 !important;
  //    padding-right: 0 !important;
  //  }
  //
  //   .navbar-collapse .navbar-nav > li > a {
  //    font-size: 14px;
  //    padding: 9px 10px;
  //  }
  //
  //   .navbar-collapse .navbar-nav > li a {
  //    border-bottom: solid 1px #eee;
  //  }
  //
  //   .navbar-collapse .navbar-nav > li > a:focus {
  //    background: none;
  //  }
  //
  //   .navbar-collapse .navbar-nav > li > a:hover {
  //    color: darken($aquamarin,20%);
  //  }
  //
  //   .navbar-collapse .navbar-nav > .active > a,
  //   .navbar-collapse .navbar-nav > .active > a:hover,
  //   .navbar-collapse .navbar-nav > .active > a:focus {
  //    background: darken($aquamarin,20%);
  //    color: $white !important;
  //  }
  //
  //   .dropdown .dropdown-submenu > a {
  //    font-size: 13px;
  //    color: #555 !important;
  //    text-transform: uppercase;
  //  }
  }

  /*Dropdown Submenu for BS3
  ------------------------------------*/
   .dropdown-submenu {
    position: relative;
  }
   .dropdown > a:after,
   .dropdown-submenu > a:after {
    //top: 8px;
    //right: 9px;
    //font-size: 11px;
    //content: "\f105";
    //position: absolute;
    //font-weight: normal;
    //display: inline-block;
    //font-family: FontAwesome;
  }

  @media (max-width: 991px) {
     .dropdown-submenu > a:after {
      content: " ";
    }
  }

   .dropdown > a:after {
    //top: 16px;
    //right: 15px;
    //content: "\f107";
  }

   .dropdown-submenu > .dropdown-menu {
    top: 3px;
    left: 100%;
    margin-top: -5px;
    margin-left: 0;
  }

  /*Submenu comes from LEFT side*/
   .dropdown-submenu > .dropdown-menu.submenu-left {
    left: -100%;
  }

   .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }

  @media (max-width: 991px) {
     .dropdown-submenu > .dropdown-menu {
      display: block;
      margin-left: 15px;
    }
  }

   .dropdown-submenu.pull-left {
    float: none;
  }

   .dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
  }

   .dropdown-menu li [class^="fa-"],
   .dropdown-menu li [class*=" fa-"] {
    left: -3px;
    width: 1.25em;
    margin-right: 1px;
    position: relative;
    text-align: center;
    display: inline-block;
  }
   .dropdown-menu li [class^="fa-"].fa-lg,
   .dropdown-menu li [class*=" fa-"].fa-lg {
    /* increased font size for fa-lg */
    width: 1.5625em;
  }


}
