html{
    height: 100%;
	box-sizing: border-box;
	margin:0;
	padding:0;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	background-color: $hellbage;
	position:relative;
	font-family: Verdana,Arial, sans-serif;
	min-height: 100%;
	margin:0;
	padding-bottom: 6rem;
	color:$dunkelgrau;

	&.no-mobile a.phonelink{
		text-decoration: none;
		cursor: default;
	}
}
//html, body, #wrap {height: 100%;}
//body > #wrap {height: auto; min-height: 100%;}

a{color:$braun;}
h1,h2,h3,h4,h5,h6{
	color:$braun;
}
h1 a,h2 a,h3 a,h4 a,h5 a,h6 a{text-decoration:none;}

h1{
	font-family: 'Sonsie One', serif;
	color:$braun;
	margin:0;
	padding: 20px 2px 30px;
	text-shadow: 1px 1px 0 $hellbage;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

h2{
	font-style:italic;
	font-size: 1.2em;
	text-shadow: 1px 1px 0 $white;
	margin:0;
	padding:0 0 30px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.copyright{
	font-size:10px;
	position:relative;
	color:$braun;
}

fieldset{
	background-color:$bage;
	border: 3px solid $braun;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding:0;
	margin:0;

	legend{
		font-weight:bold;
		width: auto;
		font-size: 1rem;
		text-shadow: 1px 1px 0 $dunkelgrau;
		background-color:$braun;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		padding:5px 10px;
		margin: 0 10px;
		color:$hellbage;
	}
}
thead{
	background-color: $braun;
	color:$white;
}
.clear{
	clear:both;
}

.loader{
	background:url('../../images/ajax-loader.gif') no-repeat center center transparent;
	min-height:50px;
}
