.form-control{
  font-size: 1em;
}

form{
  padding:1em 0;
  font-family: Verdana,Arial, sans-serif;
  margin:0;

  .formcol{
    margin:5px 10px 5px 0;

    label{
      min-width:180px;
    }
  }

  label{
    font-weight:bold;
    color:$braun;
    padding-right:5px;
    display:block;
  }
  .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $gray-300;
    //opacity: 0.5;
  }
  .form-control::-moz-placeholder { /* Firefox 19+ */
    color: $gray-300;
    //opacity: 0.5;
  }
  .form-control:-ms-input-placeholder { /* IE 10+ */
    color: $gray-300;
    //opacity: 0.5;
  }
  .form-control:-moz-placeholder { /* Firefox 18- */
    color: $gray-300;
    //opacity: 0.5;
  }
}
p.info{
  font-style:italic;
  font-size: .8rem;
}