.image-buttons{
  margin: 1.5em;
}
.image-button{
  list-style:none;
  padding:15px 4px;
  margin: 0 auto;
  text-align: center;
  overflow:hidden;

  a{
    display:block;
    overflow:hidden;
    padding:0;
    text-align:center;
    text-decoration:none;
    font-weight:bold;
    vertical-align:bottom;
    position: relative;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

    &:hover,
    &:focus{
      color:$bage;
      text-shadow: 1px 1px 0 $braun;
    }

    img{
      min-width: 100%;
      height:auto;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    .title{
      position: relative;
      top: 170px;
    }
  }
}
